<template>
  <div class="panel-detail panel-fixed">
    <div class="panel-header">
      <h1 class="page-header org-detail-logo">
        {{ `${$t('Create yard from quotation')}` }}
      </h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard'}">
            {{"Dashboard"}}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span @click="goToList" class="underline-on-hover">
            {{ 'Quotations' | translate }}
          </span>
        </li>
        <li class="breadcrumb-item">
          <span>
            {{ 'Create yard' | translate }}
          </span>
        </li>
      </ol>
    </div>
    <yard-edit />
  </div>
</template>

<script>
import { ROUTES as QUOTATION_ROUTES } from './router'
import YardEdit from '../../yard/yard/YardEdit.vue'

export default {
  name: 'QuotationToYard',
  components: {
    'yard-edit': YardEdit
  },
  data () {
    return {
      pageName: 'Create yard from quotation',
      form: {}
    }
  },
  methods: {
    goToList () {
      this.$router.push({ name: QUOTATION_ROUTES.YARD_QUOTATION_LIST })
    }
  }
}
</script>
